import type { UseFetchOptions } from 'nuxt/app';
import { useAlertStore } from '../stores/alert';

export function useApi<T>(
  url: string | (() => string),
  options?: Omit<UseFetchOptions<T>, 'default'> & { default?: () => T | Ref<T> },
) {
  return useFetch(url, {
    ...options,
    $fetch: useNuxtApp().$api,
    async onResponse({ response }: { response: Response }) {
      const apiVersion = response.headers.get('X-Api-Version');
      if (apiVersion) {
        handleApiVersion(apiVersion);
      }
    },
  });
}

function handleApiVersion(apiVersion: string) {
  const currentApiVersion = localStorage.getItem('apiVersion');
  if (!currentApiVersion) {
    localStorage.setItem('apiVersion', apiVersion);
  } else if (currentApiVersion !== apiVersion) {
    const alertStore = useAlertStore();
    localStorage.setItem('apiVersion', apiVersion);
    alertStore.show('The application has been updated. Please refresh the page.', "info");
  }
}