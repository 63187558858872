export const useAlertStore = defineStore('alertStore', {
  state: () => ({
    showAlert: false,
    alertMessage: '',
    alertType: 'info',
  }),
  actions: {
    show(message: string, type: 'info' | 'error' | 'success') {
      this.alertMessage = message;
      this.alertType = type;
      this.showAlert = true;
    },
    clear() {
      this.showAlert = false;
      this.alertMessage = '';
      this.alertType = 'info';
    },
  },
});
